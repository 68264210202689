<template>
  <v-container>
    <v-row>
      <v-col md="12">
        <v-layout>
          <v-flex md="3" class="mr-4">
            <v-text-field
              v-model.trim="search"
              append-icon="search"
              dense
              filled
              label="Search Property"
              solo
              flat
              color="cyan"
              :loading="searching"
              v-on:keyup.enter="getProperties()"
            >
            </v-text-field>
          </v-flex>
          <v-flex md="3">
            <v-btn
              v-on:click="getProperties()"
              class="mr-4"
              color="cyan white--text"
              >Search</v-btn
            >
            <v-btn v-on:click="createProperty()" color="cyan white--text"
              ><v-icon dark left>mdi-plus</v-icon> Create</v-btn
            >
          </v-flex>
          <v-flex md="3"></v-flex>
          <v-flex md="3"></v-flex>
        </v-layout>
      </v-col>
      <v-col md="12">
        <ListingTable :columnCount="6" :dataLoading="searching">
          <template v-slot:thead>
            <thead>
              <tr>
                <th class="simple-table-th">#</th>
                <th class="simple-table-th">Property #</th>
                <th class="simple-table-th">Customer</th>
                <th class="simple-table-th">Contact Person</th>
                <th class="simple-table-th">Address</th>
                <th class="simple-table-th">Total</th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="properties.length">
                <tr
                  class="alternate-listing-row"
                  v-for="(row, index) in properties"
                  :key="index"
                >
                  <td class="simple-table-td">{{ index + 1 }}</td>
                  <td class="simple-table-td">
                    <Barcode
                      :barcode="row.barcode"
                      route="property.detail"
                      :id="row.id"
                    ></Barcode>
                    <br />
                    <!-- <v-chip label color="green" outlined v-if="row.is_tenant"
                      >Tenant</v-chip
                    > -->
                  </td>
                  <td class="simple-table-td">
                    <div class="property-listing-customer">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Display Name: </b>
                        {{ row.customer.display_name }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Company: </b> {{ row.customer.company_name }}
                      </p>
                      <template v-if="!lodash.isEmpty(row.customer)">
                        <p
                          class="m-0 custom-nowrap-ellipsis"
                          v-if="!lodash.isEmpty(row.customer.default_person)"
                        >
                          <b>Email: </b>
                          {{ row.customer.default_person.primary_email }}
                        </p>
                        <p
                          class="m-0 custom-nowrap-ellipsis"
                          v-if="!lodash.isEmpty(row.customer.default_person)"
                        >
                          <b>Phone: </b>
                          {{ row.customer.default_person.primary_phone }}
                        </p>
                      </template>
                    </div>
                  </td>
                  <td class="simple-table-td">
                    <div
                      v-if="row.contact_person"
                      class="property-listing-customer"
                    >
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Display Name: </b>
                        {{ row.contact_person.display_name }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Email: </b>
                        {{ row.contact_person.primary_email }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Phone: </b>
                        {{ row.contact_person.primary_phone }}
                      </p>
                    </div>
                  </td>
                  <td class="simple-table-td">
                    <p class="m-0 custom-nowrap-ellipsis-two-line">
                      {{ row.property_address }}
                      {{ row.country_name }}
                    </p>
                  </td>
                  <td class="simple-table-td">
                    <div class="property-listing-customer">
                      <!-- <p class="m-0 custom-nowrap-ellipsis">
                        <b>Quotation: </b>
                        <template v-if="row.quotation_count">
                          <span class="ml-1 count-radius bg-light-success">{{
                            row.quotation_count
                          }}</span>
                        </template>
                        <template v-else
                          ><em class="text--secondary"
                            >no quotation</em
                          ></template
                        >
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Invoice: </b>
                        <template v-if="row.invoice_count">
                          <span class="ml-1 count-radius bg-light-success">{{
                            row.invoice_count
                          }}</span>
                        </template>
                        <template v-else
                          ><em class="text--secondary">no invoice</em></template
                        >
                      </p> -->
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Visit: </b>
                        <template v-if="row.visit_count">
                          <span class="ml-1 count-radius bg-light-success">{{
                            row.visit_count
                          }}</span>
                        </template>
                        <template v-else
                          ><em class="text--secondary">no visit</em></template
                        >
                      </p>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-else-if="!searching">
                <td :colspan="6" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no job at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";

export default {
  data() {
    return {
      search: null,
      searching: true,
      properties: [],
    };
  },
  components: {
    Barcode,
    ListingTable,
  },
  props: {
    customer: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    customer() {
      this.getProperties();
    },
  },
  methods: {
    createProperty() {
      this.$router.push(
        this.getDefaultRoute("property.create", {
          query: { customer: this.customer },
        })
      );
    },
    getProperties() {
      if (this.customer) {
        this.searching = true;
        this.$store
          .dispatch(QUERY, {
            url: `property/customer/${this.customer}`,
            data: { search: this.search, status: "all" },
          })
          .then(({ data }) => {
            this.properties = data.rows;
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.searching = false;
          });
      }
    },
  },
  mounted() {
    this.getProperties();
  },
};
</script>
